import cn from "classnames"
import React, { FC, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CSSTransition } from "react-transition-group"

import { toggleSidebar } from "../../../state/layout/layout.actions"
import { SidebarItem } from "../../../state/layout/layout.props"
import { selectIsNavbarOpen } from "../../../state/layout/layout.selectors"
import ButtonSearch from "../../button-search"
import SearchBarAside from "../../search-bar-aside"
import { IconType } from "../../svg-icon/svg-icon"
import NavLink from "../header/navbar-link"
import { INavLink } from "../header/navbar-link"

import * as navLinkStyles from "./../header/navbar-link/navbar-link.module.scss"
import * as styles from "./navbar-aside.module.scss"

export interface NavbarMenuProps {
  mediaFormLink: INavLink
  navLinks: INavLink[]
  toggleSearchBarAside(): void
  isSearchBarAsideVisible: boolean
  isDarkModeActive: boolean
  toggleDarkMode(): void
}

/**
 * @todo Would be nice to have navLink.type as enum...
 */
const NavbarAside: FC<NavbarMenuProps> = ({
  mediaFormLink,
  navLinks,
  toggleSearchBarAside,
  isSearchBarAsideVisible,
  isDarkModeActive,
  toggleDarkMode,
}) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectIsNavbarOpen)
  const materialsCollectionNavLink = useMemo(() => navLinks.find((navLink) => navLink.type === "media_collection"), [])

  const navLinksWithMediaFormLink = [...navLinks, mediaFormLink]

  const onClickSuggestion = () => {
    toggleSearchBarAside()
    dispatch(toggleSidebar(SidebarItem.NAVBAR))
  }

  return (
    <CSSTransition in={isOpen} timeout={200} classNames={styles} unmountOnExit>
      <aside className={cn("navbar-menu", styles.component)}>
        <div className={cn("navbar-start")}>
          {navLinksWithMediaFormLink.map((navLink, idx) =>
            navLink.type !== "contact" &&
            navLink.type !== "sign_language" &&
            navLink.type !== "plain_language" &&
            navLink.type !== "accessibility" &&
            navLink.type !== "media_collection" ? (
              <NavLink
                key={idx}
                {...navLink}
                tabIndex={1}
                onClick={() => dispatch(toggleSidebar(SidebarItem.NAVBAR))}
                classNames={navLinkStyles.mobileMainNavLink}
              />
            ) : null
          )}
        </div>
        <div className={cn("navbar-end aside", styles.navbarEnd)}>
          {materialsCollectionNavLink && (
            <NavLink
              label={materialsCollectionNavLink.label}
              slug={materialsCollectionNavLink.slug}
              iconType={IconType.X}
              tabIndex={1}
              isMaterialsLink={true}
              onClick={() => dispatch(toggleSidebar(SidebarItem.NAVBAR))}
            />
          )}
          <ButtonSearch
            className={cn("button-search-aside", "navbar-item", navLinkStyles.cssComponent)}
            toggleSearchBar={toggleSearchBarAside}
          />

          {isSearchBarAsideVisible ? <SearchBarAside onClickSuggestion={onClickSuggestion} /> : null}

          <div className={cn(styles.metaMenu, "meta-menu")}>
            <NavLink
              slug="#"
              label={isDarkModeActive ? "Heller Modus" : "Dunkler Modus"}
              onClick={toggleDarkMode}
              isBrand={false}
              tabIndex={1}
            />
            {navLinks.map((navLink, idx) =>
              navLink.type === "contact" || navLink.type === "sign_language" || navLink.type === "plain_language" ? (
                <NavLink
                  key={idx}
                  {...navLink}
                  tabIndex={1}
                  onClick={() => dispatch(toggleSidebar(SidebarItem.NAVBAR))}
                />
              ) : null
            )}
          </div>
        </div>
      </aside>
    </CSSTransition>
  )
}

export default NavbarAside
